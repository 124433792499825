import React, { useState } from 'react';
import { 
  BarChart2, 
  Database, 
  LineChart, 
  Search, 
  Clock, 
  Code,
  FileText,
  Users,
  Building,
  Server,
  Archive,
  PieChart,
  CheckCircle
} from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';


const getIconForCategory = (category) => {
  const iconProps = { size: 32, className: "text-indigo-600" };
  switch (category) {
    case 'fundamentals':
      return <Database {...iconProps} />;
    case 'stockPrices':
      return <LineChart {...iconProps} />;
    case 'options':
      return <BarChart2 {...iconProps} />;
    case 'etf':
      return <PieChart {...iconProps} />;
    case 'estimates':
      return <Archive {...iconProps} />;
    case 'apis':
      return <Code {...iconProps} />;
    default:
      return <Server {...iconProps} />;
  }
};

const Feature = ({ text }) => (
  <div className="flex items-center gap-2 text-gray-600">
    <CheckCircle className="h-5 w-5 text-indigo-600 flex-shrink-0" />
    <span>{text}</span>
  </div>
);

const Product = ({ icon, name, description, features }) => (
  <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 overflow-hidden">
    <div className="p-6">
      <div className="mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-3 text-gray-900">{name}</h3>
      <p className="text-gray-600 mb-6">{description}</p>
      {features && (
        <div className="space-y-3">
          {features.map((feature, index) => (
            <Feature key={index} text={feature} />
          ))}
        </div>
      )}
    </div>
  </div>
);

const products = [
  {
    title: "Financial Statement Filing APIs",
    products: [
      {
        name: 'Query API',
        description: 'Advanced search and filtering of EDGAR filings with real-time updates and comprehensive coverage.',
        category: 'query',
        features: [
          'Search across 20+ million filings since 1994',
          '275ms indexing of new filings',
          'Advanced filtering by CIK, ticker, form type',
          'Complex query support with AND/OR operators'
        ]
      },
      {
        name: 'Filing Text Search API',
        description: 'Search through the complete content of all EDGAR filings with powerful text search capabilities.',
        category: 'search',
        features: [
          'Text search across all filings since 2002',
          'Boolean operators and phrase matching',
          '75-second indexing of new filings',
          'Filter by form types and date ranges'
        ]
      },
      {
        name: 'XBRL to JSON Converter',
        description: 'Convert XBRL financial statements to standardized JSON format with comprehensive taxonomy support.',
        category: 'xbrl',
        features: [
          'Full US GAAP taxonomy support',
          'Automated context mapping',
          'All financial statement types supported',
          'Standardized JSON output format'
        ]
      },
      {
        name: "Download API",
        description: "Download any filing and exhibit from the EDGAR database.",
        category: "download",
        features: [
          "Access all file types in the EDGAR archive- HTML, XSD, XML, TXT, IDX, etc.",
          "Export filings and form exhibits to PDF format and Word documents.",
        ]
      },
      {
        name: "Section Extraction API",
        description: "The Section Extraction API extracts any text section from 10-Q, 10-K and 8-K filings.",
        category: "extract",
        features: [
          "Send the URL of the filing, the section name (e.g. Item 1A) and the return data type (e.g. raw text) to the Extractor API and the extracted content is returned.",
        ]
      },
      {
        name: "AI-Powered XBRL Standardization",
        description: "Harmonize inconsistent XBRL concepts across different companies using advanced AI models for standardized financial analysis.",
        category: "xbrl-ai",
        features: [
          "AI-driven mapping of company-specific XBRL concepts to standardized categories",
          "Historical concept matching across filings",
          "Confidence scores for mappings",
          "Automated handling of company-specific extensions",
          "Regular model updates to adapt to new reporting patterns"
        ]
      },
      {
        name: "Comparative Analysis API",
        description: "Generate automated comparative analyses across companies and sectors using standardized financial data.",
        category: "analysis",
        features: [
          "Cross-company financial ratio analysis",
          "Peer group benchmarking",
          "Industry-specific metrics and KPIs",
          "Temporal trend analysis",
          "Customizable comparison parameters"
        ]
      },
      {
        name: "Risk Analytics API",
        description: "Extract and analyze risk-related information from financial filings using natural language processing.",
        category: "risk",
        features: [
          "Risk factor identification and categorization",
          "Risk severity scoring",
          "Year-over-year risk change tracking",
          "Industry-specific risk benchmarking",
          "ESG risk factor analysis"
        ]
      },
      {
        name: "Filing Change Detection API",
        description: "Track and analyze changes between successive versions of company filings.",
        category: "changes",
        features: [
          "Automatic detection of material changes in filings",
          "Side-by-side comparison visualization",
          "Change significance scoring",
          "Automated alerts for significant changes",
          "Historical change pattern analysis"
        ]
      }
    ]
  }
];

// Rest of the component remains the same, just using the updated products array
const Products = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleStartTrial = () => {
    navigate('/contact');
  };
  
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero section with gradient background */}
      <div className="bg-gradient-to-r from-indigo-600 to-indigo-900 text-white">
        <div className="container mx-auto px-4 py-20">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Professional Financial Data Solutions
            </h1>
            <p className="text-xl md:text-2xl text-indigo-100 mb-8">
              Enterprise-grade financial data and analytics platforms powering the world's leading financial institutions
            </p>
            <div className="relative max-w-2xl mx-auto mb-8">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-3 border border-transparent rounded-lg bg-white/10 backdrop-blur-sm focus:outline-none focus:ring-2 focus:ring-white/30 text-white placeholder-white/70"
                placeholder="Search our solutions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Link
              to="/contact"
              className="inline-block bg-transparent border-2 border-white text-white px-8 py-4 rounded-full font-semibold hover:bg-white/10 transition duration-300"
            >
              Get Started For Free
            </Link>
          </div>
        </div>
        
        {/* Curved bottom */}
        <div className="relative h-16">
          <div className="absolute bottom-0 inset-x-0">
            <svg viewBox="0 0 1440 74" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 74L60 68.7C120 63.3 240 52.7 360 47.3C480 42 600 42 720 47.3C840 52.7 960 63.3 1080 68.7C1200 74 1320 74 1380 74H1440V0H1380C1320 0 1200 0 1080 0C960 0 840 0 720 0C600 0 480 0 360 0C240 0 120 0 60 0H0V74Z" fill="#f9fafb"/>
            </svg>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-12">
        {/* Stats section */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-16">
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 flex items-center gap-4">
            <div className="p-3 bg-indigo-100 rounded-lg">
              <Server className="h-6 w-6 text-indigo-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Data Points</div>
              <div className="text-2xl font-bold text-gray-900">1T+</div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 flex items-center gap-4">
            <div className="p-3 bg-indigo-100 rounded-lg">
              <Clock className="h-6 w-6 text-indigo-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Years of History</div>
              <div className="text-2xl font-bold text-gray-900">50+</div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 flex items-center gap-4">
            <div className="p-3 bg-indigo-100 rounded-lg">
              <Users className="h-6 w-6 text-indigo-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Global Clients</div>
              <div className="text-2xl font-bold text-gray-900">5000+</div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 flex items-center gap-4">
            <div className="p-3 bg-indigo-100 rounded-lg">
              <Building className="h-6 w-6 text-indigo-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Data Sources</div>
              <div className="text-2xl font-bold text-gray-900">200+</div>
            </div>
          </div>
        </div>

        {/* Products sections */}
        {products
          .filter(category => 
            category.products.some(product =>
              product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              product.description.toLowerCase().includes(searchTerm.toLowerCase())
            )
          )
          .map((category, index) => (
            <div key={index} className="mb-16">
              <div className="flex items-center gap-2 mb-8">
                <h2 className="text-2xl font-bold text-gray-900">{category.title}</h2>
                <div className="h-1 w-20 bg-indigo-600 rounded"></div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                {category.products
                  .filter(product =>
                    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    product.description.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((product, productIndex) => (
                    <Product
                      key={productIndex}
                      icon={getIconForCategory(product.category)}
                      {...product}
                    />
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Products;