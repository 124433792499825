import React, { useState } from "react";
import { Send } from "lucide-react";
import emailjs from "emailjs-com";

const Contact = () => {
  const [formData, setFormData] = useState({
    from_name: "", // Update to match EmailJS template
    reply_to: "",  // Update to match EmailJS template
    message: "",   // Update to match EmailJS template
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS parameters
    const serviceID = "service_zmbl3xl"; // Replace with your EmailJS Service ID
    const templateID = "template_x1qtj9q"; // Replace with your EmailJS Template ID
    const userID = "MyWN5kxlsGVsBz19X"; // Replace with your EmailJS Public Key

    emailjs
      .send(serviceID, templateID, formData, userID)
      .then(() => {
        setStatus("Message sent successfully!");
        setFormData({ from_name: "", reply_to: "", message: "" }); // Reset form
      })
      .catch(() => setStatus("Failed to send message. Please try again."));
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-indigo-600 via-indigo-700 to-purple-800">
      <div className="relative z-10 container mx-auto px-4 py-24">
        <div className="max-w-4xl mx-auto">
          <div className="bg-white/10 backdrop-blur-lg rounded-3xl p-12 shadow-2xl">
            <div className="text-center space-y-8">
              <h1 className="text-6xl font-bold text-white mb-6 tracking-tight">
                Let's Connect
              </h1>
              <div className="w-24 h-1 bg-gradient-to-r from-pink-500 to-indigo-500 mx-auto rounded-full"></div>
              <p className="text-2xl text-indigo-100 leading-relaxed max-w-2xl mx-auto">
                Have questions about our services? We're here to transform your ideas into reality.
              </p>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <input
                    type="text"
                    name="from_name" // Matches {{from_name}} in EmailJS template
                    placeholder="Your Name"
                    value={formData.from_name}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 rounded-xl bg-white/20 text-white placeholder-indigo-200 focus:ring-2 focus:ring-pink-500"
                  />
                </div>
                <div>
                  <input
                    type="email"
                    name="reply_to" // Matches {{reply_to}} in EmailJS template
                    placeholder="Your Email"
                    value={formData.reply_to}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 rounded-xl bg-white/20 text-white placeholder-indigo-200 focus:ring-2 focus:ring-pink-500"
                  />
                </div>
                <div>
                  <textarea
                    name="message" // Matches {{message}} in EmailJS template
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 rounded-xl bg-white/20 text-white placeholder-indigo-200 focus:ring-2 focus:ring-pink-500"
                    rows="5"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="group relative px-8 py-4 bg-white text-indigo-700 font-semibold rounded-2xl hover:bg-indigo-50 transition-all duration-300 transform hover:scale-105 hover:shadow-xl"
                >
                  <span className="flex items-center space-x-3">
                    <Send className="w-6 h-6 group-hover:animate-bounce" />
                    <span className="text-xl">Send Message</span>
                  </span>
                </button>
              </form>

              {status && <p className="text-white mt-4">{status}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
