import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, ResponsiveContainer, Cell,
  AreaChart, Area, LineChart, Line, BarChart, Bar
} from 'recharts';
import {
  Code, FileSearch, Database, Zap, Download, PieChart, ArrowRight,
  ChevronRight, Sparkles, Clock, Target, CheckCircle2, AlertTriangle,
  DollarSign, BookOpen, BarChart2
} from 'lucide-react';
import { Helmet } from 'react-helmet';

// Products Data
const products = [
  {
    title: 'Financial Statement APIs',
    products: [
      {
        name: 'Financial Statements AI',
        description: 'Advanced AI that understands financial terminology like a seasoned accountant, automating statement analysis and data extraction.',
        category: 'ai',
        features: [
          'Reduces analysis costs by 75%',
          'Halves processing time',
          'Automatic key metric computation',
          'Excel-ready data output'
        ],
        metrics: [
          'Cash and Cash Equivalents',
          'Marketable Securities',
          'Total Current Assets',
          'Total Shareholder Equity',
          'EBITDA/EBIT',
          'Short/Long-term Debt'
        ]
      },
      {
        name: 'Query API',
        description: 'Advanced search and filtering of EDGAR filings with real-time updates and comprehensive coverage.',
        category: 'query',
        features: [
          'Search across 20+ million filings since 1994',
          '275ms indexing of new filings',
          'Advanced filtering by CIK, ticker, form type',
          'Complex query support with AND/OR operators'
        ]
      },
      {
        name: 'Filing Text Search API',
        description: 'Search through the complete content of all EDGAR filings with powerful text search capabilities.',
        category: 'search',
        features: [
          'Text search across all filings since 2002',
          'Boolean operators and phrase matching',
          '75-second indexing of new filings',
          'Filter by form types and date ranges'
        ]
      },
      {
        name: 'XBRL to JSON Converter',
        description: 'Convert XBRL financial statements to standardized JSON format with comprehensive taxonomy support.',
        category: 'xbrl',
        features: [
          'Full US GAAP taxonomy support',
          'Automated context mapping',
          'All financial statement types supported',
          'Standardized JSON output format'
        ]
      },
      {
        name: "Download API",
        description: "Download any filing and exhibit from the EDGAR database.",
        category: "download",
        features: [
          "Access all file types in the EDGAR archive",
          "Export filings to PDF and Word formats",
          "Batch download capabilities",
          "Real-time access to new filings"
        ]
      },
      {
        name: "Section Extraction API",
        description: "Extract specific sections from 10-Q, 10-K and 8-K filings automatically.",
        category: "extract",
        features: [
          "Automated section identification",
          "Multiple output formats supported",
          "Historical data extraction",
          "Custom section mapping"
        ]
      }
    ]
  }
];

// Filing Data
const filingData = [
  { month: 'Sep 2023', total: 12847, '10-K': 243, '10-Q': 2156, '8-K': 7123 },
  { month: 'Oct 2023', total: 13102, '10-K': 187, '10-Q': 2891, '8-K': 7432 },
  { month: 'Nov 2023', total: 11983, '10-K': 156, '10-Q': 2654, '8-K': 6843 },
  { month: 'Dec 2023', total: 11245, '10-K': 198, '10-Q': 1987, '8-K': 6789 },
  { month: 'Jan 2024', total: 12654, '10-K': 876, '10-Q': 1543, '8-K': 7123 },
  { month: 'Feb 2024', total: 13876, '10-K': 1234, '10-Q': 1876, '8-K': 7543 }
];

// Document Processing Data
const PROCESSED_DOCS = [
  { type: '10-K', count: 2847, growth: 12 },
  { type: '10-Q', count: 8743, growth: 15 },
  { type: '8-K', count: 15234, growth: 8 },
  { type: 'Other SEC Forms', count: 4521, growth: 10 }
];

// Hero Background Component
const HeroBackground = () => (
  <div className="absolute inset-0 overflow-hidden">
    <svg className="absolute w-full h-full" preserveAspectRatio="none" viewBox="0 0 100 100">
      <defs>
        <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: '#fff', stopOpacity: 0.05 }} />
          <stop offset="100%" style={{ stopColor: '#fff', stopOpacity: 0.1 }} />
        </linearGradient>
      </defs>
      <path d="M0,0 L100,0 L100,100 L0,100 Z" fill="url(#grad)" />
      <path
        d="M0,50 Q50,0 100,50 T200,50"
        stroke="rgba(255,255,255,0.1)"
        fill="none"
        strokeWidth="0.5"
      >
        <animate
          attributeName="d"
          dur="10s"
          repeatCount="indefinite"
          values="M0,50 Q50,0 100,50 T200,50;
                  M0,50 Q50,100 100,50 T200,50;
                  M0,50 Q50,0 100,50 T200,50"
        />
      </path>
    </svg>
  </div>
);

// Animated Value Component
const AnimatedValue = ({ value, suffix = '', prefix = '' }) => {
  const [displayValue, setDisplayValue] = useState(0);
  
  useEffect(() => {
    const duration = 2000;
    const steps = 60;
    const stepValue = value / steps;
    let currentStep = 0;
    
    const timer = setInterval(() => {
      currentStep++;
      setDisplayValue(Math.min(stepValue * currentStep, value));
      if (currentStep >= steps) clearInterval(timer);
    }, duration / steps);
    
    return () => clearInterval(timer);
  }, [value]);

  return (
    <span className="font-bold">
      {prefix}{Math.round(displayValue).toLocaleString()}{suffix}
    </span>
  );
};

// Enhanced Stat Card Component
const EnhancedStatCard = ({ number, label, icon: Icon, description }) => {
  const [isHovered, setIsHovered] = useState(false);
  
  return (
    <div
      className="bg-white p-6 rounded-xl border border-gray-100 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-start gap-4">
        <div className="p-3 bg-indigo-100 rounded-lg">
          <Icon className="w-6 h-6 text-indigo-600" />
        </div>
        <div>
          <div className="text-3xl font-bold text-indigo-600 mb-1">
            <AnimatedValue value={parseFloat(number.replace(/[^0-9.]/g, ''))} suffix={number.includes('+') ? '+' : ''} />
          </div>
          <div className="text-gray-600 font-medium">{label}</div>
          <div className={`text-sm text-gray-500 mt-2 transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-4 shadow-lg rounded-lg border border-gray-100">
        <p className="font-semibold">{label}</p>
        {payload.map((entry, index) => (
          <p key={index} className="text-sm" style={{ color: entry.color }}>
            {entry.name}: {entry.value.toLocaleString()}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

// Document Card Component
const DocumentCard = ({ doc }) => (
  <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
    <div className="flex items-center gap-3">
      <FileSearch className="w-5 h-5 text-indigo-600" />
      <span className="font-medium text-gray-700">{doc.type}</span>
    </div>
    <div className="flex items-center gap-4">
      <span className="font-bold text-gray-900">
        {doc.count.toLocaleString()}
      </span>
      <div className="flex items-center text-green-600 text-sm">
        <ArrowRight className="w-4 h-4" />
        +{doc.growth}%
      </div>
    </div>
  </div>
);

// Metric Card Component
const MetricCard = ({ icon: Icon, title, value, growth }) => (
  <div className="p-4 bg-gray-50 rounded-lg">
    <div className="flex items-center gap-2 mb-2">
      <Icon className="w-5 h-5 text-indigo-600" />
      <span className="text-sm text-gray-600">{title}</span>
    </div>
    <div className="text-2xl font-bold text-gray-900">{value}</div>
    <div className="text-sm text-green-600 mt-1">{growth}</div>
  </div>
);

// Financial Statements AI Section
const FinancialStatementsAISection = () => {
  const benefits = [
    {
      title: "Automated Extraction",
      description: "Our AI reads and understands financial statements like a seasoned accountant, automatically extracting key data points",
      icon: Zap
    },
    {
      title: "Standardized Output",
      description: "Get perfectly formatted Excel files with standardized data, ready for analysis and comparison",
      icon: FileSearch
    },
    {
      title: "Time Savings",
      description: "Cut analysis time by 50% and free your team to focus on strategic decision-making",
      icon: Clock
    },
    {
      title: "Quality Assurance",
      description: "Built-in validation ensures accuracy and consistency across all extracted data",
      icon: CheckCircle2
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-br from-indigo-50 to-white">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center mb-16">
          <span className="bg-indigo-100 text-indigo-800 px-4 py-2 rounded-full text-sm font-semibold">
            Featured Solution
          </span>
          <h2 className="text-4xl font-bold mt-6 mb-4">Financial Statements AI</h2>
          <p className="text-xl text-gray-600">
            Transform how you analyze financial statements with our advanced AI solution
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <div className="space-y-6">
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <h3 className="text-2xl font-bold mb-4">The Challenge</h3>
              <p className="text-gray-600">
                Analyzing financial statements is time-consuming and cumbersome. Manual data extraction
                drains resources that should be focused on strategic initiatives. Traditional technology
                often fails due to varying document structures.
              </p>
            </div>
            
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <h3 className="text-2xl font-bold mb-4">Our Solution</h3>
              <p className="text-gray-600">
                Our advanced AI understands complex financial terminology like a seasoned accountant,
                automatically extracting data and computing key metrics. Download results instantly
                in Excel format.
              </p>
            </div>

            <div className="flex gap-4">
              <Link
                to="/contact"
                className="flex-1 bg-indigo-600 text-white px-6 py-4 rounded-lg text-center font-semibold hover:bg-indigo-700 transition duration-300"
              >
                Book a Demo
              </Link>
              <Link
                to="/contact"
                className="flex-1 border-2 border-indigo-600 text-indigo-600 px-6 py-4 rounded-lg text-center font-semibold hover:bg-indigo-50 transition duration-300"
              >
                Contact Us
              </Link>
            </div>
          </div>

          <div className="grid gap-4">
            {benefits.map((benefit, index) => (
              <div 
                key={index} 
                className="bg-white p-6 rounded-xl shadow-lg flex items-start gap-4 transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl"
              >
                <div className="p-3 bg-indigo-100 rounded-lg">
                  <benefit.icon className="w-6 h-6 text-indigo-600" />
                </div>
                <div>
                  <h4 className="font-bold text-gray-900 mb-2">{benefit.title}</h4>
                  <p className="text-gray-600 text-sm">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-12 max-w-4xl mx-auto">
          <blockquote className="text-xl text-gray-600">
            <p className="italic mb-4">
              "Processing thousands of financial statements manually was a huge bottleneck for our analysts. This AI solution has cut our processing time by 90% while maintaining institutional-grade accuracy."
            </p>
            <footer className="text-gray-800 font-medium not-italic">
              - Chief Data Officer, Leading Investment Bank
            </footer>
          </blockquote>
          
          <blockquote className="text-xl text-gray-600">
            <p className="italic mb-4">
              "The AI extraction capabilities have transformed our financial analysis workflow. What used to take our team days now happens in minutes, with even greater accuracy."
            </p>
            <footer className="text-gray-800 font-medium not-italic">
              - Director of Investment Research, Global Asset Management Firm
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
};

// Stats Section Component
const StatsSection = () => (
  <section className="py-16 bg-gradient-to-br from-gray-50 to-white">
    <div className="container mx-auto px-4">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-gray-900">Processing at Scale</h2>
        <p className="text-gray-600 mt-2">Real-time insights into our platform</p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Processing Stats */}
        <div className="bg-white rounded-xl shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-6 text-gray-900">Documents Processed This Month</h3>
          <div className="space-y-4">
            {PROCESSED_DOCS.map((doc) => (
              <DocumentCard key={doc.type} doc={doc} />
            ))}
          </div>
        </div>

        {/* Performance Metrics */}
        <div className="bg-white rounded-xl shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-6 text-gray-900">Performance Metrics</h3>
          <div className="grid grid-cols-2 gap-4">
            <MetricCard
              icon={Clock}
              title="Average Processing Time"
              value="275ms"
              growth="12% faster than last month"
            />
            <MetricCard
              icon={Target}
              title="Extraction Accuracy"
              value="99.8%"
              growth="+0.3% improvement"
            />
            <MetricCard
              icon={Database}
              title="Data Points Processed"
              value="1.2B+"
              growth="+18% this quarter"
            />
            <MetricCard
              icon={Sparkles}
              title="AI Model Updates"
              value="Weekly"
              growth="Continuous learning"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

// Products Section Component
const ProductsSection = () => (
  <section className="py-16">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold mb-12 text-center text-gray-900">
        Complete Suite of Financial Analysis Tools
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {products[0].products.map((product, index) => (
          <div
            key={index}
            className="p-6 bg-white rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1"
          >
            <h4 className="text-xl font-bold mb-3 text-indigo-600">{product.name}</h4>
            <p className="text-gray-600 mb-4">{product.description}</p>
            <ul className="space-y-2">
              {product.features.map((feature, featIdx) => (
                <li key={featIdx} className="flex items-start gap-2 text-sm text-gray-500">
                  <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="text-center mt-12">
        <Link
          to="/products"
          className="inline-flex items-center gap-2 bg-indigo-600 text-white px-8 py-4 rounded-full font-semibold hover:bg-indigo-700 transition-all duration-300 transform hover:-translate-y-1"
        >
          View All Products
          <ArrowRight className="w-5 h-5" />
        </Link>
      </div>
    </div>
  </section>
);

// Live Updates Bar Component
const LiveUpdatesBar = () => {
  const [currentUpdate, setCurrentUpdate] = useState(0);
  const [processingTime, setProcessingTime] = useState(
    Math.floor(Math.random() * (325 - 250 + 1)) + 250
  );
  const [activeClients, setActiveClients] = useState(
    Math.floor(Math.random() * (100 - 50 + 1)) + 50
  );

  const generateRandomNumbers = () => {
    setProcessingTime(Math.floor(Math.random() * (325 - 250 + 1)) + 250);
    setActiveClients(Math.floor(Math.random() * (100 - 50 + 1)) + 50);
  };

  const getCurrentMessage = (index) => {
    const messages = [
      { message: `New 10-K filing processed in ${processingTime}ms`, type: "success" },
      { message: "Real-time data available for Q4 earnings", type: "info" },
      { message: `${activeClients} institutional clients using our API right now`, type: "success" },
      { message: "Processing 2,400+ financial documents per hour", type: "info" }
    ];
    return messages[index].message;
  };

  useEffect(() => {
    const messageTimer = setInterval(() => {
      setCurrentUpdate((prev) => (prev + 1) % 4);
    }, 4000);

    const numberTimer = setInterval(generateRandomNumbers, 50000);

    return () => {
      clearInterval(messageTimer);
      clearInterval(numberTimer);
    };
  }, []);

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg z-50 border-t border-gray-200">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Sparkles className="w-5 h-5 text-indigo-600 animate-pulse" />
            <span className="text-gray-700">{getCurrentMessage(currentUpdate)}</span>
          </div>
          <Link
            to="/products"
            className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition duration-300 flex items-center gap-2"
          >
            Try it now <ArrowRight className="w-4 h-4" />
          </Link>
        </div>
      </div>
    </div>
  );
};

// CTA Section Component
const CTASection = () => (
  <div className="bg-gradient-to-r from-indigo-600 to-purple-600 py-16 px-4 my-16 rounded-3xl relative overflow-hidden">
    <div className="absolute inset-0 bg-grid-white/5" />
    <div className="relative z-10 max-w-4xl mx-auto text-center text-white">
      <h2 className="text-4xl font-bold mb-6">Ready to Transform Your Financial Analysis?</h2>
      <p className="text-xl mb-8 text-indigo-100">
        Join leading institutions using our API to process millions of financial documents daily.
      </p>
      <div className="flex gap-4 justify-center">
        <Link
          to="/contact"
          className="bg-white text-indigo-600 px-8 py-4 rounded-full font-semibold hover:bg-indigo-100 transition duration-300 flex items-center gap-2"
        >
          Book a Demo <ArrowRight className="w-5 h-5" />
        </Link>
        <Link
          to="/products"
          className="bg-transparent border-2 border-white text-white px-8 py-4 rounded-full font-semibold hover:bg-white/10 transition duration-300"
        >
          View Products
        </Link>
      </div>
    </div>
  </div>
);

// Main Home Component
const Home = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Financial Statements API | Real-Time Filing Analysis</title>
        <meta 
          name="description" 
          content="Transform financial statements into actionable intelligence using AI, delivering analysis with millisecond precision and institutional-grade accuracy." 
        />
      </Helmet>

      {/* Hero Section */}
      <section className="min-h-[90vh] flex items-center justify-center bg-gradient-to-br from-indigo-600 via-indigo-700 to-purple-800 text-white relative overflow-hidden">
        <HeroBackground />
        <div className="text-center relative z-10 px-4 max-w-5xl mx-auto">
          <div className="inline-block mb-6 px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full">
            <span className="text-indigo-200 text-sm sm:text-base">Trusted by 150+ Financial Institutions</span>
          </div>
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-6 leading-tight sm:whitespace-nowrap px-4">
            AI-Powered<br className="sm:hidden" /> Financial Analysis
          </h1>
          <p className="text-lg sm:text-xl mb-8 text-indigo-100 max-w-2xl mx-auto px-4 sm:whitespace-nowrap">
            Transform how you analyze financial statements with our suite of intelligent APIs
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center px-4">
            <Link
              to="/contact"
              className="bg-white text-indigo-600 px-8 py-4 rounded-full font-semibold hover:bg-indigo-100 transition duration-300 flex items-center justify-center gap-2 group"
            >
              Book a Demo
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </Link>
            <Link
              to="/contact"
              className="bg-transparent border-2 border-white text-white px-8 py-4 rounded-full font-semibold hover:bg-white/10 transition duration-300"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </section>

      {/* Financial Statements AI Section */}
      <FinancialStatementsAISection />

      {/* Products Section */}
      <ProductsSection />
      
      {/* Stats Section */}
      <StatsSection />

      {/* Live Updates Bar */}
      <LiveUpdatesBar />

      {/* CTA Section */}
      <CTASection />

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
            {['Products', 'APIs', 'Pricing', 'Contact'].map((item) => (
              <Link
                key={item}
                to={`/${item.toLowerCase()}`}
                className="text-lg font-semibold hover:text-indigo-400 transition-colors flex items-center gap-2"
              >
                <ChevronRight className="w-4 h-4" />
                {item}
              </Link>
            ))}
          </div>
          <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>&copy; 2024 Data Filings. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;