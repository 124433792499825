import React, { useState } from 'react';
import { Search } from 'lucide-react';

const CodeBlock = ({ content }) => (
  <div className="bg-slate-900 rounded p-4 mt-4">
    <pre className="text-slate-100 overflow-x-auto whitespace-pre-wrap text-sm font-mono">
      {content}
    </pre>
  </div>
);

const APIFeatureGrid = ({ features }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
    {features.map((feature, index) => (
      <div key={index} className="bg-slate-50 rounded p-4">
        <div className="font-medium text-slate-900 mb-2">{feature.title}</div>
        {feature.list ? (
          <ul className="text-slate-600 space-y-2">
            {feature.items.map((item, i) => (
              <li key={i} className="flex items-center">
                <span className="mr-2">•</span>
                {item}
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-slate-600">{feature.description}</p>
        )}
      </div>
    ))}
  </div>
);

const APIContent = ({ api }) => {
  if (!api) return null;
  
  return (
    <div className="divide-y divide-slate-200">
      <section className="p-6">
        <h2 className="text-2xl font-bold text-slate-900 mb-4">{api.title}</h2>
        <p className="text-slate-600 mb-6">{api.description}</p>
        <div className="bg-sky-50 rounded p-4 border border-sky-100">
          <h3 className="font-medium text-sky-900 mb-2">Endpoint</h3>
          <div className="flex items-center gap-2">
                <span className={`text-sm font-mono px-2 py-1 rounded ${
                  api.method === 'GET' ? 'bg-green-100 text-green-700' : 'bg-indigo-100 text-indigo-700'
                }`}>
              {api.method}
            </span>
            <code className="bg-white px-3 py-1 rounded text-sky-600 text-sm">
              {api.endpoint}
            </code>
          </div>
        </div>
      </section>

      {api.example && (
        <section className="p-6">
          <h3 className="text-xl font-bold text-slate-900 mb-4">Example Usage</h3>
          {api.example.request && <CodeBlock content={api.example.request} />}
          {api.example.response && (
            <div className="mt-4">
              <h4 className="text-lg font-semibold mb-2">Response</h4>
              <CodeBlock content={api.example.response} />
            </div>
          )}
        </section>
      )}

      {api.features && (
        <section className="p-6">
          <h3 className="text-xl font-bold text-slate-900 mb-4">Features</h3>
          <APIFeatureGrid features={api.features} />
        </section>
      )}
    </div>
  );
};

const APIDocs = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState('query');

  const apiData = {
    query: {
      title: "Query API",
      description: "Search and filter EDGAR filings with advanced query capabilities. Access over 20 million filings published since 1994, with new filings indexed within 275 milliseconds of publication.",
      endpoint: "/query",
      method: "POST",
      example: {
        request: 
`const response = await fetch('https://api.datafilings.com/query', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    query: "formType:\\"10-Q\\" AND ticker:AAPL",
    from: 0,
    size: 10,
    sort: [{ filedAt: { order: "desc" }}]
  })
});

const data = await response.json();`,
        response:
`{
  "total": { 
    "value": 156,
    "relation": "eq"
  },
  "filings": [{
    "accessionNo": "0001437749-20-002201",
    "cik": "924383",
    "ticker": "AAPL",
    "companyName": "Apple Inc.",
    "formType": "10-Q",
    "filedAt": "2024-02-10T19:28:05-05:00"
  }]
}`
      },
      features: [
        {
          title: "Comprehensive Coverage",
          description: "Search across 20+ million filings since 1994"
        },
        {
          title: "Real-time Updates",
          description: "275ms indexing of new filings"
        },
        {
          title: "Advanced Filtering",
          description: "Filter by CIK, ticker, form type"
        },
        {
          title: "Complex Queries",
          description: "Support for AND/OR operators"
        }
      ]
    },
    "filing-text-search": {
      title: "Filing Text Search API",
      description: "Search through the complete content of all EDGAR filings since 2002. New filings are searchable within 75 seconds of publication.",
      endpoint: "/filing-text-search",
      method: "POST",
      example: {
        request:
`const response = await fetch('https://api.datafilings.com/full-text-search', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    query: "substantial doubt",
    formTypes: ["8-K", "10-K"],
    startDate: "2024-01-01",
    endDate: "2024-03-31",
    page: 1
  })
});

const results = await response.json();`,
        response:
`{
  "total": {
    "value": 86,
    "relation": "eq"
  },
  "filings": [{
    "accessionNo": "0000320193-20-000008",
    "cik": "320193",
    "companyName": "Apple Inc.",
    "formType": "8-K",
    "filedAt": "2024-01-28",
    "filingUrl": "https://www.sec.gov/..."
  }]
}`
      },
      features: [
        {
          title: "Search Capabilities",
          list: true,
          items: [
            "Boolean operators",
            "Phrase matching",
            "Filing-text search"
          ]
        },
        {
          title: "Coverage",
          list: true,
          items: [
            "All filings since 2002",
            "75-second indexing",
            "Form type filtering"
          ]
        }
      ]
    },
    "xbrl": {
      title: "XBRL to JSON Converter",
      description: "Convert XBRL financial statements to standardized JSON format with comprehensive taxonomy support and automated context mapping.",
      endpoint: "/xbrl-to-json",
      method: "POST",
      example: {
        request:
`const response = await fetch('https://api.datafilings.com/xbrl-to-json', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    filing_url: "https://www.sec.gov/Archives/edgar/data/...",
    output_format: "json",
    include_contexts: true
  })
});

const xbrlData = await response.json();`,
        response:
`{
  "StatementsOfIncome": {
    "Revenue": [{
      "value": "274515000000",
      "decimals": "-6",
      "unitRef": "usd",
      "period": {
        "startDate": "2023-01-01",
        "endDate": "2023-12-31"
      }
    }]
  }
}`
      },
      features: [
        {
          title: "Taxonomy Support",
          list: true,
          items: [
            "Full US GAAP taxonomy",
            "Automated context mapping",
            "All statement types"
          ]
        },
        {
          title: "Output Format",
          list: true,
          items: [
            "Standardized JSON",
            "Context preservation",
            "Detailed metadata"
          ]
        }
      ]
    },
    "download": {
      title: "Download API",
      description: "Download any filing and exhibit from the EDGAR database in various formats.",
      endpoint: "/download",
      method: "GET",
      example: {
        request:
`const response = await fetch('https://api.datafilings.com/download', {
  method: 'GET',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY'
  },
  params: {
    accessionNo: "0000320193-20-000008",
    format: "pdf"
  }
});

const downloadUrl = await response.json();`,
        response:
`{
  "status": "success",
  "downloadUrl": "https://api.example.com/download/..." 
}`
      },
      features: [
        {
          title: "EDGAR Archive Files",
          list: true,
          items: [
            "HTML",
            "XSD",
            "XML",
            "TXT",
            "IDX"
          ]
        },
        {
          title: "Export Formats",
          list: true,
          items: [
            "PDF",
            "Word documents"
          ]
        }
      ]
    },
    "section-extraction": {
      title: "Section Extraction API",
      description: "Extract specific text sections from 10-Q, 10-K and 8-K filings with intelligent section recognition.",
      endpoint: "/extract-section",
      method: "POST",
      example: {
        request:
`const response = await fetch('https://api.datafilings.com/extract-section', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    filing_url: "https://www.sec.gov/...",
    section: "Item 1A",
    return_type: "text"
  })
});

const section = await response.json();`,
        response:
`{
  "section": "Item 1A",
  "content": "Risk Factors\\n\\nThe following risks...",
  "word_count": 2500
}`
      },
      features: [
        {
          title: "Section Types",
          list: true,
          items: [
            "Risk Factors (Item 1A)",
            "MD&A",
            "Financial Statements",
            "Notes to Financial Statements"
          ]
        },
        {
          title: "Extraction Features",
          list: true,
          items: [
            "Intelligent section recognition",
            "Multiple format support",
            "Context preservation",
            "Section metadata"
          ]
        }
      ]
    },
    "xbrl-ai": {
      title: "AI-Powered XBRL Standardization",
      description: "Harmonize inconsistent XBRL concepts across different companies using advanced AI models for standardized financial analysis.",
      endpoint: "/xbrl-standardize",
      method: "POST",
      example: {
        request:
`const response = await fetch('https://api.datafilings.com/xbrl-standardize', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    filing_url: "https://www.sec.gov/...",
    standardization_level: "strict",
    mapping_confidence_threshold: 0.8,
    include_historical_matches: true
  })
});

const result = await response.json();`,
        response:
`{
  "mappings": [{
    "original_concept": "CompanySpecificRevenue",
    "standardized_concept": "Revenue",
    "confidence_score": 0.98,
    "historical_matches": 245,
    "value": "1234567",
    "context": "FY2023"
  }],
  "unmapped_concepts": [],
  "model_version": "2024.1"
}`
      },
      features: [
        {
          title: "AI Capabilities",
          list: true,
          items: [
            "AI-driven concept mapping",
            "Historical concept matching",
            "Confidence scoring",
            "Regular model updates"
          ]
        },
        {
          title: "Standardization",
          list: true,
          items: [
            "Company-specific extensions",
            "Automated handling",
            "Pattern adaptation",
            "Taxonomy updates"
          ]
        }
      ]
    },
    "comparative": {
      title: "Comparative Analysis API",
      description: "Generate automated comparative analyses across companies and sectors using standardized financial data.",
      endpoint: "/compare",
      method: "POST",
      example: {
        request:
`const response = await fetch('https://api.datafilings.com/compare', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    companies: ["AAPL", "MSFT", "GOOGL"],
    metrics: ["revenue_growth", "operating_margin"],
    period: "TTM",
    include_industry_avg: true,
    benchmarks: {
      sector: "Technology",
      market_cap_range: "large_cap"
    }
  })
});

const analysis = await response.json();`,
        response:
`{
  "comparative_metrics": {
    "revenue_growth": {
      "AAPL": 0.15,
      "MSFT": 0.18,
      "GOOGL": 0.12,
      "sector_avg": 0.14,
      "peer_avg": 0.15
    }
  },
  "rankings": {
    "overall_performance": ["MSFT", "AAPL", "GOOGL"]
  }
}`
      },
      features: [
        {
          title: "Analysis Types",
          list: true,
          items: [
            "Cross-company ratios",
            "Peer benchmarking",
            "Industry metrics",
            "Custom KPIs"
          ]
        },
        {
          title: "Comparison Features",
          list: true,
          items: [
            "Temporal analysis",
            "Industry averages",
            "Performance rankings",
            "Custom parameters"
          ]
        }
      ]
    },
    "risk": {
      title: "Risk Analytics API",
      description: "Extract and analyze risk-related information from financial filings using natural language processing.",
      endpoint: "/risk-analysis",
      method: "POST",
      example: {
request:
`const response = await fetch('https://api.datafilings.com/risk-analysis', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    cik: "320193",
    analysis_type: "risk_factors",
    period: "2023-2024",
    include_sentiment: true,
    track_changes: true,
    categories: ["operational", "financial", "regulatory", "strategic"]
  })
});

const riskAnalysis = await response.json();`,
        response:
`{
  "risk_factors": [{
    "category": "Operational",
    "severity": 0.8,
    "trend": "increasing",
    "description": "Supply chain disruptions...",
    "sentiment": -0.6,
    "year_over_year_change": 0.15,
    "industry_comparison": "above_average",
    "related_events": [
      {
        "date": "2024-01-15",
        "description": "Factory shutdown in..."
      }
    ]
  }],
  "summary": {
    "total_risks": 24,
    "high_severity": 8,
    "trending_up": 5,
    "new_risks": 3
  }
}`
      },
      features: [
        {
          title: "Risk Assessment",
          list: true,
          items: [
            "Factor identification",
            "Severity scoring",
            "Trend analysis",
            "Change tracking"
          ]
        },
        {
          title: "Analysis Types",
          list: true,
          items: [
            "Industry benchmarking",
            "ESG risk factors",
            "Sentiment analysis",
            "Event correlation"
          ]
        }
      ]
    },
    "changes": {
      title: "Filing Change Detection API",
      description: "Track and analyze changes between successive versions of company filings with automated detection and significance scoring.",
      endpoint: "/detect-changes",
      method: "POST",
      example: {
        request:
`const response = await fetch('https://api.datafilings.com/detect-changes', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    previous_filing: "0000320193-23-000008",
    current_filing: "0000320193-24-000008",
    sections: ["Risk Factors", "MD&A"],
    sensitivity: "high",
    include_context: true
  })
});

const changes = await response.json();`,
        response:
`{
  "changes": [{
    "section": "Risk Factors",
    "type": "addition",
    "content": "New regulatory requirements...",
    "significance_score": 0.85,
    "context": {
      "paragraph": "In response to evolving...",
      "location": "Item 1A, paragraph 3"
    },
    "category": "regulatory_risk"
  }],
  "summary": {
    "total_changes": 12,
    "significant_changes": 3,
    "by_section": {
      "Risk Factors": 5,
      "MD&A": 7
    },
    "by_type": {
      "additions": 4,
      "deletions": 3,
      "modifications": 5
    }
  }
}`
      },
      features: [
        {
          title: "Detection Capabilities",
          list: true,
          items: [
            "Material changes",
            "Side-by-side comparison",
            "Change significance",
            "Context analysis"
          ]
        },
        {
          title: "Analysis Features",
          list: true,
          items: [
            "Automated alerts",
            "Historical patterns",
            "Change categorization",
            "Impact assessment"
          ]
        }
      ]
    }
  };

  const tabs = [
    { id: 'query', label: 'Query' },
    { id: 'filing-text-search', label: 'Filing Text Search' },
    { id: 'xbrl', label: 'XBRL to JSON' },
    { id: 'download', label: 'Download' },
    { id: 'section-extraction', label: 'Section Extraction' },
    { id: 'xbrl-ai', label: 'XBRL AI Standardization' },
    { id: 'comparative', label: 'Comparative Analysis' },
    { id: 'risk', label: 'Risk Analytics' },
    { id: 'changes', label: 'Change Detection' }
  ];

  const filteredTabs = searchTerm 
    ? tabs.filter(tab => {
        const apiInfo = apiData[tab.id];
        return tab.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
               (apiInfo && apiInfo.description.toLowerCase().includes(searchTerm.toLowerCase()));
      })
    : tabs;

    return (
      <div className="min-h-screen bg-slate-50">
        {/* Header with gradient */}
        <div className="bg-gradient-to-r from-indigo-600 to-indigo-900 text-white">
          <div className="container mx-auto px-4 py-16">
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="text-4xl font-bold mb-6">
                 Financial Statement Filing API Documentation
              </h1>
              <p className="text-xl text-indigo-100 mb-8">
                Complete reference for modern REST APIs to access filing data
              </p>
              
              <div className="relative max-w-2xl mx-auto">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  className="block w-full pl-10 pr-3 py-3 border border-transparent rounded-lg bg-white/10 backdrop-blur-sm focus:outline-none focus:ring-2 focus:ring-white/30 text-white placeholder-white/70"
                  placeholder="Search APIs..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
    
        {/* Content */}
        <div className="container mx-auto px-4 py-12">
          <div className="bg-white rounded-xl shadow-sm border border-gray-100">
            {/* Tabs */}
            <div className="border-b border-gray-200">
              <div className="overflow-x-auto">
                <nav className="flex space-x-6 px-4">
                  {filteredTabs.map((tab) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`
                        py-4 px-2 border-b-2 font-medium text-sm whitespace-nowrap transition-colors
                        ${activeTab === tab.id
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                      `}
                    >
                      {tab.label}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
    
            {/* API Content */}
            {apiData[activeTab] && <APIContent api={apiData[activeTab]} />}
          </div>
        </div>
      </div>
    );
};

export default APIDocs;