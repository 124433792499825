import React, { useState } from 'react';
import { 
  BarChart2, 
  Database, 
  LineChart, 
  Search, 
  Clock, 
  Code,
  FileText,
  Users,
  Building,
  Server,
  Archive,
  PieChart
} from 'lucide-react';

const getIconForCategory = (category) => {
  const iconProps = { size: 32, className: "text-indigo-600" };
  switch (category) {
    case 'fundamentals':
      return <Database {...iconProps} />;
    case 'stockPrices':
      return <LineChart {...iconProps} />;
    case 'options':
      return <BarChart2 {...iconProps} />;
    case 'etf':
      return <PieChart {...iconProps} />;
    case 'estimates':
      return <Archive {...iconProps} />;
    case 'apis':
      return <Code {...iconProps} />;
    default:
      return <Server {...iconProps} />;
  }
};

const Product = ({ icon, name, description }) => (
  <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 overflow-hidden">
    <div className="p-6">
      <div className="mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-gray-900">{name}</h3>
      <p className="text-gray-600 mb-4 min-h-[60px]">{description}</p>
    </div>
  </div>
);

const CategorySection = ({ title, products }) => (
  <div className="mb-16">
    <div className="flex items-center gap-2 mb-8">
      <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
      <div className="h-1 w-20 bg-indigo-600 rounded"></div>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {products.map((product, index) => (
        <Product
          key={index}
          icon={getIconForCategory(product.category)}
          {...product}
        />
      ))}
    </div>
  </div>
);

const products = [
  {
    title: "Financial Statement Filing APIs",
    products: [
      {
        name: "Query API",
        description: "Advanced search and filtering of EDGAR filings with real-time updates and comprehensive coverage.",
        category: "apis",
      },
      {
        name: "Full-Text Search API",
        description: "Search through the complete content of all EDGAR filings with powerful text search capabilities.",
        category: "apis",
      },
      {
        name: "XBRL to JSON Converter",
        description: "Convert XBRL financial statements to standardized JSON format with comprehensive taxonomy support.",
        category: "apis",
      },
      {
        name: "Download API",
        description: "Download any filing and exhibit from the EDGAR database.",
        category: "apis",
      },
      {
        name: "Section Extraction API",
        description: "Extract sections from 10-Q, 10-K, and 8-K filings.",
        category: "apis",
      },
      {
        name: "AI-Powered XBRL Standardization",
        description: "Harmonize inconsistent XBRL concepts across different companies using advanced AI models for standardized financial analysis.",
        category: "apis",
      },
      {
        name: "Comparative Analysis API",
        description: "Generate automated comparative analyses across companies and sectors using standardized financial data.",
        category: "apis",
      },
      {
        name: "Risk Analytics API",
        description: "Extract and analyze risk-related information from financial filings using natural language processing.",
        category: "apis",
      },
      {
        name: "Filing Change Detection API",
        description: "Track and analyze changes between successive versions of company filings.",
        category: "apis",
      }
    ]
  },
];

const Products = () => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-gradient-to-r from-indigo-600 to-indigo-900 text-white">
        <div className="container mx-auto px-4 py-20">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Financial Data Solutions
            </h1>
            <p className="text-xl md:text-2xl text-indigo-100 mb-8">
              Access comprehensive financial data through our professional-grade APIs and data services
            </p>
            <div className="relative max-w-2xl mx-auto">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-3 border border-transparent rounded-lg bg-white/10 backdrop-blur-sm focus:outline-none focus:ring-2 focus:ring-white/30 text-white placeholder-white/70"
                placeholder="Search our products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-16">
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 flex items-center gap-4">
            <div className="p-3 bg-indigo-100 rounded-lg">
              <Server className="h-6 w-6 text-indigo-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Total APIs</div>
              <div className="text-2xl font-bold text-gray-900">9+</div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 flex items-center gap-4">
            <div className="p-3 bg-indigo-100 rounded-lg">
              <FileText className="h-6 w-6 text-indigo-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Filings</div>
              <div className="text-2xl font-bold text-gray-900">20M+</div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 flex items-center gap-4">
            <div className="p-3 bg-indigo-100 rounded-lg">
              <Users className="h-6 w-6 text-indigo-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Active Users</div>
              <div className="text-2xl font-bold text-gray-900">5000+</div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 flex items-center gap-4">
            <div className="p-3 bg-indigo-100 rounded-lg">
              <Building className="h-6 w-6 text-indigo-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Supported Clients</div>
              <div className="text-2xl font-bold text-gray-900">150+</div>
            </div>
          </div>
        </div>

        {products.map((category, index) => (
          <CategorySection key={index} {...category} />
        ))}

        {/* Single Contact Button */}
        <div className="text-center mt-16">
          <a href="/contact" className="inline-block px-10 py-4 bg-indigo-600 text-white font-semibold text-xl rounded-lg shadow-lg hover:bg-indigo-700">
            Contact Us for Price
          </a>
        </div>
      </div>
    </div>
  );
};

export default Products;
