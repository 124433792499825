import React, { useState, useEffect } from 'react';
import { 
  Brain, 
  FileText, 
  Database, 
  Network,
  Layers,
  GitBranch,
  CheckCircle,
  Search
} from 'lucide-react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';

// Keep existing FeatureCard component...
const FeatureCard = ({ icon: Icon, title, description, capabilities }) => (
  <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 p-6">
    <div className="mb-4">
      <Icon className="h-12 w-12 text-indigo-600" />
    </div>
    <h3 className="text-xl font-semibold mb-3 text-gray-900">{title}</h3>
    <p className="text-gray-600 mb-4">{description}</p>
    {capabilities && (
      <ul className="space-y-2">
        {capabilities.map((capability, index) => (
          <li key={index} className="flex items-start gap-2 text-gray-600">
            <CheckCircle className="h-5 w-5 text-indigo-600 flex-shrink-0 mt-1" />
            <span>{capability}</span>
          </li>
        ))}
      </ul>
    )}
  </div>
);

// Add the Grid component from home.js
const Grid = () => {
  const gridLines = [];
  const count = 10;
  const opacity = 0.12;

  for (let i = 0; i <= count; i++) {
    const position = (i / count) * 100;
    const label = Math.round(position);
    gridLines.push(
      <g key={`v-${i}`}>
        <line
          x1={`${position}%`}
          y1="0%"
          x2={`${position}%`}
          y2="100%"
          stroke="#6366f1"
          strokeWidth={i % 2 === 0 ? 0.5 : 0.2}
          strokeOpacity={i % 2 === 0 ? opacity * 2 : opacity}
          strokeDasharray={i % 2 === 0 ? 'none' : '2,2'}
        />
        <text
          x={`${position}%`}
          y="100%"
          dy="1em"
          textAnchor="middle"
          fill="#6b7280"
          fontSize="10"
        >
          {label}
        </text>
      </g>
    );

    gridLines.push(
      <g key={`h-${i}`}>
        <line
          x1="0%"
          y1={`${position}%`}
          x2="100%"
          y2={`${position}%`}
          stroke="#6366f1"
          strokeWidth={i % 2 === 0 ? 0.5 : 0.2}
          strokeOpacity={i % 2 === 0 ? opacity * 2 : opacity}
          strokeDasharray={i % 2 === 0 ? 'none' : '2,2'}
        />
        <text
          x="0%"
          y={`${position}%`}
          dx="-1em"
          textAnchor="end"
          alignmentBaseline="middle"
          fill="#6b7280"
          fontSize="10"
        >
          {label}
        </text>
      </g>
    );
  }

  return <g>{gridLines}</g>;
};

// Add CustomTooltip component from home.js
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-4 shadow-lg rounded-lg border border-gray-100">
        <p className="font-semibold">{label}</p>
        {payload.map((entry, index) => (
          <p key={index} className="text-sm" style={{ color: entry.color }}>
            {entry.name}: {entry.value.toLocaleString()}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

// Add FinancialConceptVisualization component
const FinancialConceptVisualization = () => {
  const clusterColors = {
    Assets: {
      primary: '#4338ca',
      secondary: '#6366f1',
      gradient: ['#4338ca', '#3730a3']
    },
    Liabilities: {
      primary: '#dc2626',
      secondary: '#ef4444',
      gradient: ['#dc2626', '#b91c1c']
    },
    Revenue: {
      primary: '#059669',
      secondary: '#10b981',
      gradient: ['#059669', '#047857']
    },
    Expenses: {
      primary: '#d97706',
      secondary: '#f59e0b',
      gradient: ['#d97706', '#b45309']
    },
    Equity: {
      primary: '#7c3aed',
      secondary: '#8b5cf6',
      gradient: ['#7c3aed', '#6d28d9']
    }
  };

  const generateDataSet = () => {
    const xbrlConcepts = {
      Assets: [
        'Assets',
        'CurrentAssets',
        'CashAndCashEquivalents',
        'Investments',
        'AccountsReceivable',
        'Inventory',
        'PropertyPlantAndEquipment'
      ],
      Liabilities: [
        'Liabilities',
        'CurrentLiabilities',
        'AccountsPayable',
        'LongTermDebt',
        'AccruedLiabilities',
        'DeferredRevenue',
        'LeaseObligations'
      ],
      Revenue: [
        'Revenue',
        'OperatingRevenue',
        'ServiceRevenue',
        'ProductRevenue',
        'InterestIncome',
        'OtherIncome',
        'GrossProfit'
      ],
      Expenses: [
        'Expenses',
        'OperatingExpenses',
        'CostOfSales',
        'ResearchAndDevelopment',
        'SellingGeneralAndAdministrative',
        'InterestExpense',
        'Depreciation'
      ],
      Equity: [
        'StockholdersEquity',
        'RetainedEarnings',
        'CommonStock',
        'AdditionalPaidInCapital',
        'AccumulatedOtherComprehensiveIncome',
        'TreasuryStock',
        'MinorityInterest'
      ]
    };
    const basePoints = {
      Assets: { x: 75, y: 85, z: 80 },
      Liabilities: { x: 25, y: 25, z: 30 },
      Revenue: { x: 65, y: 15, z: 70 },
      Expenses: { x: 35, y: 65, z: 40 },
      Equity: { x: 45, y: 45, z: 60 }
    };

    const generateClusterPoints = (center, count) => {
      const points = [];
      for (let i = 0; i < count; i++) {
        const angle = (i / count) * Math.PI * 2;
        const radius = 8 + Math.random() * 4;

        const mainPoint = {
          x: center.x + Math.cos(angle) * radius,
          y: center.y + Math.sin(angle) * radius,
          z: center.z + (Math.random() - 0.5) * 10
        };
        points.push(mainPoint);

        const subClusterCount = Math.floor(2 + Math.random() * 3);
        for (let j = 0; j < subClusterCount; j++) {
          const subAngle = Math.random() * Math.PI * 2;
          const subRadius = 2 + Math.random() * 2;
          points.push({
            x: mainPoint.x + Math.cos(subAngle) * subRadius,
            y: mainPoint.y + Math.sin(subAngle) * subRadius,
            z: mainPoint.z + (Math.random() - 0.5) * 5
          });
        }
      }
      return points;
    };

    let data = [];
    let id = 0;

    Object.entries(basePoints).forEach(([category, center]) => {
      data.push({
        id: id++,
        x: center.x,
        y: center.y,
        z: center.z,
        size: 16,
        category,
        name: xbrlConcepts[category][0],
        label: xbrlConcepts[category][0],
        isCenter: true,
        confidence: 1,
        relationships: 8,
        volatility: Math.random() * 0.3,
        density: 0.8 + Math.random() * 0.2
      });

      const points = generateClusterPoints(center, 6);
      points.forEach((point, index) => {
        data.push({
          id: id++,
          x: point.x,
          y: point.y,
          z: point.z,
          size: 4 + Math.random() * 6,
          category,
          name: xbrlConcepts[category][index + 1] || `${category}Concept${index + 1}`,
          label: xbrlConcepts[category][index + 1] || `${category}Concept${index + 1}`,
          isCenter: false,
          confidence: 0.5 + Math.random() * 0.5,
          relationships: Math.floor(3 + Math.random() * 5),
          volatility: 0.3 + Math.random() * 0.2
        });
      });
    });

    return data;
  };

  const [data] = useState(generateDataSet);
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setRotation((prev) => (prev + 0.2) % 360);
    }, 50);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-white p-6 rounded-xl shadow-lg relative">
      <div className="absolute top-4 left-4 z-10 bg-white/95 backdrop-blur-sm p-4 rounded-lg shadow-lg border border-gray-200">
        <div className="text-sm font-medium text-gray-900 mb-3">Categories</div>
        <div className="space-y-2">
          {Object.entries(clusterColors).map(([category, colors]) => (
            <div key={category} className="flex items-center gap-2">
              <div
                className="w-3 h-3 rounded-full"
                style={{
                  background: `linear-gradient(to bottom right, ${colors.gradient[0]}, ${colors.gradient[1]})`
                }}
              />
              <span className="text-sm text-gray-600">{category}</span>
            </div>
          ))}
        </div>
      </div>

      <ResponsiveContainer width="100%" height={600}>
        <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
          <defs>
            {Object.entries(clusterColors).map(([category, colors]) => (
              <linearGradient key={category} id={`gradient-${category}`} x1="0" y1="0" x2="1" y2="1">
                <stop offset="0%" stopColor={colors.gradient[0]} />
                <stop offset="100%" stopColor={colors.gradient[1]} />
              </linearGradient>
            ))}
          </defs>

          <Grid />
          <XAxis
            type="number"
            dataKey="x"
            domain={[0, 100]}
            tick={{ fontSize: 12, fill: '#6b7280' }}
            tickCount={10}
          />
          <YAxis
            type="number"
            dataKey="y"
            domain={[0, 100]}
            tick={{ fontSize: 12, fill: '#6b7280' }}
            tickCount={10}
          />
          <ZAxis type="number" dataKey="z" range={[100, 1000]} />
          <Tooltip content={<CustomTooltip />} />

          {data.map((point, index) => (
            <g key={`label-${index}`}>
              {point.isCenter && (
                <text
                  x={point.x + Math.sin((rotation * Math.PI) / 180) * 2 * point.volatility}
                  y={point.y + Math.cos((rotation * Math.PI) / 180) * 2 * point.volatility}
                  textAnchor="middle"
                  fill={clusterColors[point.category].primary}
                  fontSize={12}
                  fontWeight="bold"
                  dy={-10}
                >
                  {point.label}
                </text>
              )}
            </g>
          ))}

          <Scatter
            data={data.map((point) => ({
              ...point,
              x: point.x + Math.sin((rotation * Math.PI) / 180) * (point.isCenter ? 2 : 4) * point.volatility,
              y: point.y + Math.cos((rotation * Math.PI) / 180) * (point.isCenter ? 2 : 4) * point.volatility
            }))}
            onMouseEnter={(_, index) => setHoveredPoint(data[index])}
            onMouseLeave={() => setHoveredPoint(null)}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  entry.isCenter
                    ? `url(#gradient-${entry.category})`
                    : clusterColors[entry.category].primary
                }
                stroke={clusterColors[entry.category].secondary}
                strokeWidth={entry.isCenter ? 2 : 1}
                opacity={
                  hoveredPoint
                    ? entry.id === hoveredPoint.id
                      ? 1
                      : 0.6
                    : entry.isCenter
                    ? 1
                    : 0.8
                }
              />
            ))}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

const AISolutions = () => {
  const features = [
    {
      icon: Database,
      title: "Intelligent XBRL Standardization",
      description: "Advanced AI-driven model to standardize and normalize XBRL data across taxonomies, adapting to varying reporting standards and industry needs.",
      capabilities: [
        "Automated taxonomy alignment and mapping",
        "Historical trend analysis for consistency assurance",
        "Industry-specific framework matching",
        "Real-time variance detection and anomaly tracking"
      ]
    },
    {
      icon: Search,
      title: "Robust Quality Assurance",
      description: "End-to-end data validation framework designed to ensure precision, reliability, and consistency in standardized financial data.",
      capabilities: [
        "Automated and customizable consistency checks",
        "Real-time and historical pattern validation",
        "Multi-source cross-reference verification",
        "Automated anomaly detection and flagging for review"
      ]
    },
    {
      icon: FileText,
      title: "Custom AI-Powered Financial Reports",
      description: "Generate tailored financial reports using AI-driven XBRL standardization and preference-based customization, allowing personalized insights across financial metrics.",
      capabilities: [
        "User-defined metric selection and report customization",
        "AI-powered XBRL data categorization and summarization",
        "On-demand reporting aligned to industry and user-specific standards",
        "Automated trend analysis and insights generation"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-indigo-600 to-indigo-900 text-white">
        <div className="container mx-auto px-4 py-20">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Advanced XBRL Processing & Standardization
            </h1>
            <p className="text-xl md:text-2xl text-indigo-100 mb-8">
              Transforming complex financial documents into standardized, machine-readable data through advanced neural networks and financial domain expertise
            </p>
          </div>
        </div>
        
        {/* Curved bottom */}
        <div className="relative h-16">
          <div className="absolute bottom-0 inset-x-0">
            <svg viewBox="0 0 1440 74" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 74L60 68.7C120 63.3 240 52.7 360 47.3C480 42 600 42 720 47.3C840 52.7 960 63.3 1080 68.7C1200 74 1320 74 1380 74H1440V0H1380C1320 0 1200 0 1080 0C960 0 840 0 720 0C600 0 480 0 360 0C240 0 120 0 60 0H0V74Z" fill="#f9fafb"/>
            </svg>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        {/* Stats Section */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-16">
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <div className="text-4xl font-bold text-indigo-600 mb-2">99.8%</div>
            <div className="text-gray-600">Mapping Accuracy</div>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <div className="text-4xl font-bold text-indigo-600 mb-2">1M+</div>
            <div className="text-gray-600">Processed Filings</div>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <div className="text-4xl font-bold text-indigo-600 mb-2">50ms</div>
            <div className="text-gray-600">Processing Time</div>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <div className="text-4xl font-bold text-indigo-600 mb-2">15+</div>
            <div className="text-gray-600">Supported Taxonomies</div>
          </div>
        </div>
        
        <div className="mb-20">
          <h2 className="text-3xl font-bold text-center mb-12">XBRL Concept Mapping</h2>
          <FinancialConceptVisualization />
        </div>
        
        {/* Core Features */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold text-center mb-12">Technical Capabilities</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>

        {/* Technical Overview */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8 mb-20">
          <h2 className="text-3xl font-bold mb-8">Our Approach</h2>
          <div className="prose max-w-none">
            <p className="text-gray-600 mb-6">
              Our system utilizes specialized financial language models to process and standardize XBRL filings. The architecture leverages financial LLMs for semantic understanding of financial concepts, coupled with custom-trained models for taxonomy mapping and standardization.
            </p>
            <p className="text-gray-600 mb-6">
              The core processing pipeline includes:
            </p>
            <ul className="space-y-4 text-gray-600 mb-6">
              <li className="flex items-start gap-2">
                <CheckCircle className="h-5 w-5 text-indigo-600 flex-shrink-0 mt-1" />
                <span>Semantic analysis of financial documents using specialized language models</span>
              </li>
              <li className="flex items-start gap-2">
                <CheckCircle className="h-5 w-5 text-indigo-600 flex-shrink-0 mt-1" />
                <span>Dynamic template generation based on historical filing patterns</span>
              </li>
              <li className="flex items-start gap-2">
                <CheckCircle className="h-5 w-5 text-indigo-600 flex-shrink-0 mt-1" />
                <span>Automated mapping of custom extensions to standard taxonomies</span>
              </li>
              <li className="flex items-start gap-2">
                <CheckCircle className="h-5 w-5 text-indigo-600 flex-shrink-0 mt-1" />
                <span>Intelligent validation through cross-reference and pattern analysis</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AISolutions;