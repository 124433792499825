// src/compliance.js
import React from 'react';

/**
 * Compliance Information
 */
const complianceInfo = {
  accessStatement: `Our platform provides streamlined access to EDGAR filings, enabling businesses, analysts, and researchers to quickly query, retrieve, and analyze SEC filings. We leverage publicly available data from the U.S. Securities and Exchange Commission (SEC) EDGAR database, adhering to all SEC guidelines and usage policies to ensure compliance, transparency, and reliability.`,
  
  keyPoints: [
    {
      title: "Compliance with SEC Terms",
      description: `We strictly adhere to the SEC's Terms of Use for EDGAR data. Our service respects the SEC's access guidelines, including rate limits and responsible data usage practices. We ensure all data remains true to its original source and free from misrepresentation.`
    },
    {
      title: "Data Integrity and Accuracy",
      description: `We maintain high standards of data integrity and accuracy. Each query returns unaltered SEC data, allowing users to make informed decisions with reliable information. We do not modify, interpret, or alter the original SEC data in any way.`
    },
    {
      title: "Secure, Scalable Access",
      description: `Our platform is designed for efficiency and scalability, allowing organizations to integrate EDGAR data into their workflows securely. We implement robust security measures to protect the integrity of the data during transmission and storage.`
    },
    {
      title: "Privacy and Responsible Use",
      description: `We take data privacy seriously, ensuring that our data aggregation and query processes comply with relevant privacy standards and data handling practices. We do not collect or store personal information from SEC filings beyond what is publicly available.`
    },
    {
      title: "Regular Compliance Reviews",
      description: `We conduct regular internal reviews of our compliance practices and update our systems and policies to reflect any changes in SEC regulations or guidelines. Our team actively monitors SEC releases, staff interpretations, and other guidance documents to ensure ongoing compliance.`
    }
  ],

  userGuidelines: {
    dataAttribution: `Users must properly attribute any data obtained through our platform to the SEC and EDGAR. When citing or using the data, please include a reference to the original SEC filing and EDGAR as the source.`,
    userResponsibility: `Users are responsible for ensuring their use of the data complies with SEC regulations and any applicable laws. Our platform provides access to the data, but users must exercise due diligence in their interpretation and application of the information.`
  },

  complianceCommitment: `We are committed to maintaining the highest standards of compliance with SEC guidelines. Our team regularly reviews and updates our practices to ensure alignment with current SEC regulations. We engage legal and financial experts to periodically review our compliance statements and practices.`,

  disclaimer: `While we strive to ensure the accuracy and timeliness of the data provided, users should be aware that the definitive source for SEC filings is the SEC's official website. Users should verify critical information directly with the SEC or the reporting companies when making important decisions.`,

  lastUpdated: "2024-11-11T22:00:00-05:00",
  version: "1.1.0"
};

/**
 * Compliance Component
 */
const Compliance = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Compliance Information</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Access Statement</h2>
        <p>{complianceInfo.accessStatement}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Key Points</h2>
        {complianceInfo.keyPoints.map((point, index) => (
          <div key={index} className="mb-4">
            <h3 className="text-xl font-medium mb-2">{point.title}</h3>
            <p>{point.description}</p>
          </div>
        ))}
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">User Guidelines</h2>
        <h3 className="text-xl font-medium mb-2">Data Attribution</h3>
        <p>{complianceInfo.userGuidelines.dataAttribution}</p>
        <h3 className="text-xl font-medium mb-2 mt-4">User Responsibility</h3>
        <p>{complianceInfo.userGuidelines.userResponsibility}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Compliance Commitment</h2>
        <p>{complianceInfo.complianceCommitment}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Disclaimer</h2>
        <p>{complianceInfo.disclaimer}</p>
      </section>

      <footer className="text-sm text-gray-600">
        <p>Last Updated: {new Date(complianceInfo.lastUpdated).toLocaleDateString()}</p>
        <p>Version: {complianceInfo.version}</p>
      </footer>
    </div>
  );
};

// Exporting Compliance component as default
export default Compliance;